import "./App.css"
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import { Footer } from "./components/footer";
import { Header } from "./components/header";
import { Col, Image, Row } from "react-bootstrap";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path={"/"} element={
          <div className={""}>
            <Header />
            <div className={'screenBody'}>
              {/* <div className="centerEverything">
                <p className="centerEverything">
                  <p className="" style={{ fontSize: 26, marginBottom: -15 }}>
                    <span style={{ color: '#72fa41' }}>{'SUSTAINABILITY '}</span>
                  </p>
                  <p className="" style={{ fontSize: 26, marginBottom: -15 }}>
                    <span style={{ color: '#24ccff' }}>{'TEAMPOWER '}</span>
                  </p>
                  <p className="" style={{ fontSize: 26, marginBottom: -15 }}>
                    <span style={{ color: '#fbff00' }}>{'ENERGY '}</span>
                  </p>
                  <p className="" style={{ fontSize: 26, marginBottom: 40 }}>
                    <span style={{ color: '#ff69b4' }}>{'MEDICINE'}</span>
                  </p>
                </p>
              </div> */}
              <div style={{ margin: '5vh' }}>
                {[
                  {
                    text: 'STEM Tech Network is a social learning network for STEM professionals, students, and enthusiasts. We provide a variety of ways for users to connect, network, and create – all in an effort to shape a better future for all.',
                    image: 'https://github.com/Zernach/zernach.github.io/blob/main/images/gifs/1_STEM_TECH_NETWORK_GIF.gif?raw=true',
                  },
                  {
                    text: 'We offer an inclusive platform for learners, professionals, and global citizens to educate and collaborate on a broad range of important topics — from food & agriculture, to neurotechnology & psychology, engineering & manufacturing, to the sciences, to transportation, telecommunications, and more!',
                    image: 'https://github.com/Zernach/zernach.github.io/blob/main/images/gifs/2_STEM_TECH_NETWORK_GIF.gif?raw=true'
                  }
                ].map((intro, index) => (
                  <Row className="justify-content-center align-items-center" style={{ marginTop: '40px' }}>
                    <Col lg={{ span: 4, order: (index + 2) % 2 ? 2 : 1 }} xs={{ span: 12, order: (index + 2) % 2 ? 2 : 2 }} >
                      <Row
                        className="d-flex justify-content-center align-items-center"
                        style={{ minHeight: 200 }}
                      >
                        {intro?.image &&
                          <Image
                            src={intro?.image}
                            alt={intro?.imageTitle}
                            style={{ borderRadius: '40px', marginTop: '30px' }}
                          />
                        }
                        {intro?.video &&
                          <iframe
                            title={intro?.imageTitle}
                            src={intro?.video}
                            style={{ marginRight: '-20px', width: "44vw", height: "100%", borderRadius: "20px", boxShadow: '0px 0px 10px 0px #ffffff99', alignSelf: 'center' }}
                            allow={"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"}
                            allowfullscreen={true}
                          />
                        }
                        <p style={{ fontSize: '15px', marginTop: '10px', color: '#ffffff99' }}>
                          {intro?.imageCaption}
                        </p>
                      </Row>
                    </Col>
                    <Col lg={{ span: 8, order: (index + 2) % 2 ? 1 : 2 }} xs={{ span: 12, order: (index + 2) % 2 ? 1 : 1 }} className="d-flex justify-content-center align-items-center" style={{ paddingLeft: 30, paddingRight: 30 }}>
                      <div style={{ marginBottom: '30px' }}>
                        <h2 style={{ color: '#ffffff', textAlign: 'center' }}>
                          {intro?.text}
                        </h2>
                      </div>
                    </Col>
                  </Row>
                ))}
              </div>
            </div>
            <Footer />
          </div>
        }
        />
        <Route path="/privacy-policy" element={
          <div className="App">
            <header className="App-header">
              <img src={require('./assets/stem_icons_dark.png')} className="app-icon" alt="app-icon" style={{ marginTop: 30 }} />
              <h1 className="stemTitle">
                <span style={{ color: '#72fa41' }}>{'S'}</span>
                <span style={{ color: '#24ccff' }}>{'T'}</span>
                <span style={{ color: '#fbff00' }}>{'E'}</span>
                <span style={{ color: '#ff69b4' }}>{'M'}</span>
              </h1>
              <h3 className="techNetwork">
                <span>{'TECH'}</span>
                <span>{' NETWORK'}</span>
              </h3>
              <p className="subtitle" style={{ color: '#ffffff', marginTop: 10 }}><i>{'Terms, Conditions, & Privacy Policy'}</i></p>
              <p className="subtitle" style={{ color: '#ffffff' }}><i>{'End-User License Agreement (EULA)'}</i></p>
              <div style={{ marginRight: '20%', marginLeft: '20%', ...styles.blackBox }}>
                <p className="comingSoon" style={{ color: "#24ccff", fontSize: 22, fontWeight: 'bold', marginBottom: 50, marginTop: 20 }}>
                  {'The most important part of this agreement is that there is no tolerance for objectionable content or abusive users on this platform.'}
                </p>
                {`${`STEM Tech Network (the \u201cCompany,\u201d \u201cSTEM,\u201d \u201cwe,\u201d \u201cour,\u201d \u201cus\u201d) processes your Personal Data with our website & mobile app only in accordance with applicable data protection and privacy laws.
  
  We provide an online Social Learning Network. By creating an account with our software, you agree that you may neither sue nor hold us liable in any way for content that is posted by any of our users.
  
  Your permission will be requested to receive notifications. Your account may be associated with multiple devices, but a single device may only be associated with a single user.
  
  When you share a post, your permission will also be requested to use your device's camera/microphone for capturing images, videos, and audio to include in your post.
  
  Any unique content that any of our users share in our social learning network becomes our property — meaning, we have the right to use that content in any way we see fit — including, but not limited to, posting it on our website, using it in our marketing materials, etc.
  
  We reserve the right to remove any content that we deem to be inappropriate, and we reserve the right to terminate any user's account at any time for any reason. This includes, but is not limited to, any user who violates our terms of agreement, who posts objectionable content, or who is abusive to other users.
  
  We also reserve the right to change these terms of agreement at any time by posting them here on our website.
  
  If you have any questions about these terms of agreement, please contact us at stem@zernach.com`}`.split('\n').filter((word) => word !== '').map((phrase, phraseIndex) => (
                  <p key={`index${phraseIndex}`} className="comingSoon" style={{ color: "#ffffff" }}>
                    {phrase}
                  </p>
                ))}
              </div>
            </header>
            <Footer />
          </div >
        }
        />
      </Routes >
    </Router >
  )
}

const styles = {
  blackBox: {
    backgroundColor: '#00000099',
    marginLeft: '2%',
    marginRight: '2%',
    paddingTop: '2%',
    paddingBottom: '2%',
    marginTop: '2%',
    marginBottom: '2%',
    borderRadius: 10
  },
}

export default App
