/*
* Generally, components should not contain internal state/logic!
* Generally, we want all state/logic variables to be in the screen-functions.ts files so we have access to them all in one place.
* This is because we want to be able to easily change the state/logic of the screen in one place.
* It is okay to include internal state/logic if multiple instances of the component are needed on a single screen.
* Also worth mentioning: you may choose to use the presets file to create new variations of this component.
* Don't forget to add all props in the interface file!
*/

import * as React from "react"
import "../App.css"
// import { Navbar } from "./navbar";

/**
 * Describe the new component here...
 */
export const Header = () => {

    return (
        <header>
            <div className="flexed" style={{ justifyContent: 'space-evenly', alignItems: 'center' }}>
                <div className={'headerSection'}>
                    <h1 className="stemTitle">
                        <span style={{ color: '#72fa41', fontSize: '10vw' }}>{'S'}</span>
                        <span style={{ color: '#24ccff', fontSize: '10vw' }}>{'T'}</span>
                        <span style={{ color: '#fbff00', fontSize: '10vw' }}>{'E'}</span>
                        <span style={{ color: '#ff69b4', fontSize: '10vw' }}>{'M'}</span>
                    </h1>
                    <h3 className="techNetwork" style={{ fontSize: '3vw', marginBottom: '3vh' }}>
                        <span>{'TECH'}</span>
                        <span>{' NETWORK'}</span>
                    </h3>
                    <p className="subtitle" style={{ color: '#ffffff', fontSize: '2.5vw' }}>
                        <i>{'Future-Focused Social Learning Network'}</i>
                    </p>
                </div>
                <div className={'headerSection'}>
                    <img src={require('../assets/stem_icons_dark.png')} className="app-icon" alt="app-icon" style={{}} />
                </div>
                <div className={'headerSection'}>
                    <div style={{ flexDirection: 'row' }}>
                        <a href={'https://apps.apple.com/us/app/stem-tech-network/id1612728604'}>
                            <img src={require('../assets/download-on-apple-apple-store-stem-tech-network.png')} className="App-logo" alt="logo" />
                        </a>
                        <a href={'https://play.google.com/store/apps/details?id=com.zernach.stem'}>
                            <img src={require('../assets/get-it-on-google-playstore-app-stem-tech-network.png')} className="App-logo" alt="logo" />
                        </a>
                    </div>
                </div>
            </div>
            {/* <Navbar /> */}
        </header>
    );
};
